* {
  box-sizing: border-box;
}

html {
  touch-action: manipulation;
  font-size: 18px;
}

/* Body */
body {
  background-color: rgb(22,22,22);
  color: white;
  margin: 0;
  padding: 0;
  font-family: 'Cabin', Verdana, Tahoma, sans-serif;

}

#root {
  min-width: 100vw;
  /* min-height: 100dvh; */
  min-height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: column nowrap;
} 