.video-embed {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  background-color: black;
}

.vid-icon {
  position: absolute;
  margin-left: -1rem;
  font-size: 1rem;
}

.pin {
  rotate: -45deg;
  font-size: 1.5rem;
}

@media screen and (max-width: 650px) {

  .vid-icon {
    margin-left: 0;
  }

  .pin {
    margin-top: -5px;
  }

}