.admin-tool {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.admin-article {
  max-width: none;
  width: 95%;
  min-height: 450px;
  padding: .5rem .5rem;
  margin-bottom: auto;
}

.admin-buttons {
  z-index: 15;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-self: center;
  width: 90%;
  margin-bottom: -1rem;
}

.admin-buttons button {
  margin: 0 1rem;
  padding: .5rem .75rem;
  background-color: rgb(22,22,22);
  color: rgb(187,136,136);
  border: 1px rgba(37, 0, 0, .50) solid;
  border-radius: 3px;
  box-shadow: 1px 1px 8px -3px rgb(0,0,0, 0.6);
}

.admin-buttons button:hover {
  color: rgb(54,1,1);
  background-color: rgb(187,136,136);
  
}

.active-tool {
  color: rgb(54,1,1) !important;
  background-color: rgb(187,136,136) !important;
}

.tool-list {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border: 2px rgb(22,22,22) solid;
  background-color: rgb(22,22,22);
  border-radius: 8px;
  padding: 4px 4px 10px 4px;
  margin-top: 1.5rem;
}

.list-columns {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: .5rem 0;

  /* border: 1px orange solid; */
  background-color: rgb(22,22,22);
}

.list-columns > div {
  flex: 1;
  width: 100px;
  /* border: 1px purple solid; */
}

.list-columns > .hidden-button {
  width: 3rem;
  border: none;
  background-color: rgba(255, 255, 255, 0);
}

/* special style for the add-video and copy emails buttons on the top of the tools */
.list-columns > .tool-top-button {
  width: 3rem;
  max-width: 3rem;
  border: 1px rgba(37, 0, 0, .50) solid;
  border-radius: 3px;
  align-self: stretch;
  background-color: rgb(54,1,1);
  color: rgb(187,136,136);
}

.list-columns > .tool-top-button:hover {
  color: rgb(54,1,1);
  background-color: rgb(187,136,136);
}

.list-entry {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: white;
  color: black;
  border-bottom: 1px rgb(120, 120, 120) solid;
  /* border: 1px pink solid; */
}

.list-entry > div {
  flex: 1;
  width: 100px;
  border-right: 1px rgb(120, 120, 120) solid;
  height: 1.5rem;
  overflow-y: scroll;
}

.list-entry > div:last-of-type {
  border-right: none;
}

.list-entry > button {
  width: 3rem;
  border: none;
  align-self: stretch;
  background-color: rgb(54,1,1);
  color: rgb(187,136,136);
}

.list-entry > button:hover {
  color: rgb(54,1,1);
  background-color: rgb(187,136,136);
}

.tool-form-container {
  z-index: 50;
  position: absolute;
  top: 15rem;
  left: 50%;
  transform: translate(-50%, -15rem);
  width: 80%;
  max-width: 600px;
}

.tool-form-container.user {
  max-width: 400px;
}

.tool-heading > button {
  position: absolute;
  top: 1rem;
  right: 0;
  width: 2rem;
  height: 2rem;
  border: none;
  background-color: rgb(54,1,1);
  color: rgb(187,136,136);
  box-shadow: 1px 1px 8px -3px rgb(0,0,0, 0.6);
}

.tool-heading > button:hover {
  color: rgb(54,1,1);
  background-color: rgb(187,136,136);
}

.tool-form-container > .register-form {
  background-color: rgb(22,22,22) !important;
  width: 100%
}

.tool-form-container button {
  margin: .5rem;
}

.tool-form-container .checkbox {
  margin-right: -25%;
}

.tool-form-container .input-section.other {
  margin: 0;
}

@media screen and (max-width: 650px) {

  .tool-form-container {
    width: 85%;
  }

  .admin-article {
    width: 98%;
  }

}


  