.register-form {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  width: 75%;
  max-width: 750px;
  min-height: 60vh;

  padding: .5rem 2rem;
  margin: 1rem auto;

  background-color: rgba(54, 1, 1, 0.88);
  box-shadow: 2px 1px 8px 3px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.register-header {
  text-align: center;
}

.account-detail-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.account-detail-header button {
  margin: 0;
  padding: .5rem 1rem;
}

.register-form .input-section {
  width: 85%;
  justify-content: space-between;
}

.input-label {
  padding: .5rem;
}

.input-section.other {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin-top: 1rem;
}

.input-section.vertical {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  width: 90%;
}

.input-section.vertical input {
  width: 100%;
  border: none;
  padding-top: 1rem;
}

.input-section.vertical textarea {
  resize: none;
  width: 100%;
  height: 8rem;
  border: none;
  border-radius: 3px;
  padding-top: 1rem;
}

.input-section.vertical .input-label {
  z-index: 15;
  background-color: white;
  color: black;
  border-radius: 3px;
  padding: 0 .5rem;
  margin-bottom: -.5rem;
  text-decoration: underline;
}

.register-form input {
  width: 70%;
  max-width: none;
  border: none;
  border-radius: 3px;
}

.register-form input:focus {
  outline: none;
  border-right: 5px solid red;
  animation: focusanimate 3s infinite;
}

.input-section textarea:focus {
  outline: none;
  border-right: 5px solid red;
  animation: focusanimate 3s infinite;
}

@keyframes focusanimate {
  50% {
    border-right: 5px solid rgba(255, 116, 116, 0.573);
  }
}

.other select {
  padding: .5rem;
  width: 5rem;
  height: 100%;
  border: none;
  border-radius: 3px;
}

.other .input-label {
  padding: .5rem;
  text-align: left;
  flex: 1;
}

.checkbox {
  margin-left: -25%;
  flex: 1;
  width: 30px;
  height: 30px;
}

.register-button {
  margin: 1rem .5rem;
  padding: 1rem 1.25rem;
  background-color: rgb(22,22,22);
  color: rgb(187,136,136);
  border: 1px rgba(37, 0, 0, .50) solid;
  border-radius: 3px;
  box-shadow: 1px 1px 8px -3px rgb(0,0,0, 0.6);
}

.register-button:hover {
  color: rgb(54,1,1);
  background-color: rgb(187,136,136);
}

.form-submission-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.form-text-fields {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 80%;
}

@media screen and (max-width: 650px) {
  .register-form {
    width: 98%;
    padding: .5rem .5rem;
  }

  .input-section.other {
    width: 90%;
  }

  .input-section.vertical {
    width: 100%;
  }

  .form-text-fields {
    width: 95%;
  }

}