.content-container {
  margin: 1.5rem auto 0 auto;
  padding-top: 2rem;
  width: 100%;
  background-attachment: fixed;

  /* background-image: url(../img/nightmareLogo2.jpeg); */
  background-image: url(../img/harvesterLogo_transparent_update2024.png);
  background-repeat: no-repeat;
  background-position: 50% 3rem;
  background-size: auto 85%;


  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  /* this should make the content take up the rest of the page */
  flex: 1;

}

.home {
  background-image: none;
}

/* Main Article Section */

article {
  position: relative;
  width: 90%;
  max-width: 1080px;
  padding: .5rem 2rem;
  margin: .5rem auto;
  text-align: justify;

  background-color: rgba(54, 1, 1, 0.88);
  box-shadow: 2px 1px 8px 3px rgba(0, 0, 0, 0.4);
  border-radius: 5px;

}

.article-heading {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  font-family: 'Cinzel', Times, serif;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  z-index: 50;
}

.article-signature {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items:flex-start;
  text-align: center;
  font-family: 'Cinzel', Times, serif;

  z-index: 10;
  height: 50vh;
}

.article-signature > .emoji, h1 {
  margin-top: 2rem;
}

p {
  font-size: 1.2rem;
}

h1 {
  font-size: 3rem;
}

.emoji {
  font-size: 3rem;
  color: white;
}

.home-logo-container {
  width: 90%;
  max-width: 900px;
  margin-top: -1.5rem;
}

.home-logo-container img {
  margin: 0 auto;
  width: 100%;
  height: auto;
}

.pic-container.home {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

#homepage-headshot {
  z-index: -1;
  margin: -5% auto 0 auto;
  width: auto;
  height: 120%;
  mask-image: radial-gradient(rgba(0,0,0,1) 27%, rgba(0,0,0,0.4) 40%, rgba(0,0,0,0.1) 58%,rgba(0,0,0,0) 68%);
}

.pic-container.project {
  min-width: 300px;
  max-width: 400px;
  padding: 1rem;
  flex: 1;
}

#about-pic {
  width: 100%;
  height: auto;
}

.about-project {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.about-project p {
  flex: 1;
  min-width: 30%;
  font-size: 1.4rem;
  text-align: center;
}

.pic-container.people {
  width: 60%;
  max-width: 500px;
  float: left;
  padding: 0 1rem 0 0;
}

@media screen and (max-width: 1000px) {

  .article-signature > .emoji {
    padding-top: 1rem;
  }

}

/* MOBILE */


@media screen and (max-width: 650px) {

.content-container {
  width: 100%;
  background-size: 135% auto;
}

article {
  width: 98%;
  padding: .5rem .5rem;
}

.home-logo-container {
  width: 100%;
}

h1 {
  font-size: 2rem;
}

p {
  font-size: 1rem;
}

.article-signature > .emoji, h1 {
  font-size: 2rem;
  margin-top: 1.5rem;
}

.article-signature > .emoji {
  padding: 0rem .25rem;
}

.about-article {
  flex-flow: column nowrap;
}

.about-article p {
  text-align: justify;
  font-size: 1.2rem;
}

#homepage-headshot {
  width: 100%;
  height: auto;
  max-width: 400px;
}


.pic-container.project {
  max-width: none;
  float: right;
  padding: 0;
}

.pic-container.people {
  float: none;
  width: 100%; 
  padding: 0;
  margin: 0 auto;
}

  
}

@media screen and (max-width: 600px) {

  .article-signature > .emoji {
    padding: 1rem .25rem;
  }

}

@media screen and (max-width: 500px) {

  .content-container {
    background-size: 170% auto;
  }

}