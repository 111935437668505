.donate-section {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  /* border: 1px red solid; */
}

.donate-section > p {
  width: 40%;
  text-align: center;
  font-size: 1.5rem;
  margin-right: 2rem;
  /* border: 1px pink solid; */
}

@media screen and (max-width: 650px) {

  .donate-section {
    flex-flow: column nowrap;
  }

  .donate-section > p {
    width: 90%;
    margin-right: 0;
  }

}