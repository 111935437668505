/* Footer */

footer {
  z-index: 15;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  background-color: rgb(54, 1, 1);
  color: rgb(187,136,136);
}

.copyright {
  text-align: center;
  padding: .5rem 2rem;
  font-size: 1rem;

  /* border-top: 1px rgb(187,136,136) solid; */
}

.social-media {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  width: 70%;
  min-height: 1rem;
  max-width: 500px;
  padding: 1rem;

  border-bottom: 1px rgb(187,136,136) solid;
}

.social-media a {
  color: rgb(187,136,136);
}

.social-media a:hover {
  color: white;
}

@media screen and (max-width: 650px) {

  .copyright {
    font-size: .75rem;
  }

  .social-media {
    width: 90%;
  }


}
