/* Login Form */

.login-window {
  display: flex;
  flex-flow: row nowrap;
  position: fixed;
  top: 3rem;
  right: 0;

  background-color: rgba(22, 22, 22, 1.0);
  border: 2px rgb(54,1,1) solid;
  border-radius: 3px;
  box-shadow: 2px 1px 4px 2px rgba(0, 0, 0, 0.4);
}

.login-form {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 20rem;
  padding: 1rem .5rem;
  


  color: white;
}

.input-section {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: .5rem;
}

.input-section input {
  float: right;
  padding: .5rem;
  width: 90%;
  min-width: 150px;
  border: none;
  border-radius: 3px;
}

.input-section input:focus {
  outline: none;
  border-right: 5px solid red;
  animation: focusanimate 5s infinite;
}

@keyframes focusanimate {
  50% {
    border-right: 5px solid rgba(255, 116, 116, 0.573);
  }
}

.login-register-buttons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin-top: .5rem;
}

.login-button {
  padding: 1rem 2rem;
  background-color: rgb(54,1,1);
  color: rgb(187,136,136);
  border: 1px rgba(37, 0, 0, .50) solid;
  border-radius: 3px;
  box-shadow: 1px 1px 8px -3px rgb(0,0,0, 0.4);
  flex: 1;
}

.login-button:hover {
  color: rgb(54,1,1);
  background-color: rgb(187,136,136);
}

/* for the register link */
.login-register-buttons a {
  font-size: .75rem;
  padding: 1rem;
  text-align: center;

  /* the "navbar a" style in the header.css interferes here, so I have to add this min-width 0px to keep it from expanding in mobile view */
  /* min-width: 0px; */
  /* flex: 1; */
}

.login-register-buttons a:hover {
  background-color: rgba(22, 22, 22, 1.0);
  text-decoration: underline;
}

.closer {
  display: none;
}


@media screen and (max-width: 650px) {
  .login-window {
    left: 0;
    width: 100%;
  }

  .login-form {
    width: 85%;
  }

  input {
    height: 2.5rem;
  }

  .login-register-buttons {
    flex-flow: column nowrap;
  }

  .login-button {
    padding: 1rem 4rem;
  }

  .closer {
    display: block;
    position: absolute;
    top: 0;
    right: -3px;
    padding: 0;
    height: 3rem;
    width: 3rem;
    z-index: 500;
  }

}