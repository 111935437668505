header {
  display: flex;
  flex-flow: row nowrap;
  width: 100vw;
  min-height: 3rem;
  position: fixed;
  top: 0;
  z-index: 100;
  
  background-color: rgb(22,22,22);
  color: white;
  box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.4);
  
}

/* Navigation Bar */

.navbar {
  position: relative;
  z-index: 100;
  width: 100%;
  overflow: hidden;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.home-link {
  width: 16rem;
  height: 100%;
  margin-right: auto;

  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;

  font-family: 'Cinzel', Times, serif;
  font-size: 1.1rem;
}

.home-link a {
  width: 100%;
  height: 100%;
}

.other-nav {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.navbar a {
  min-width: 7rem;
  padding: .25rem;
  color: white;
  text-align: center;
  text-decoration: none;
  align-self: stretch;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.navbar a:hover {
  background-color: rgb(54,1,1);
}

.navbar a:active {
  background-color: rgb(54,1,1);
}

.active-link {
  background-color: rgb(54,1,1);
}

.icon {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  height: 3rem;
  color: rgb(187,136,136);
  background-color: rgb(54,1,1);
}

.icon:hover {
  color: rgb(54,1,1);
  background-color: rgb(187,136,136);
}

/* login/user section */

.user-button {
  border: none;
  padding: 0 .5rem;
  background-color: rgb(54,1,1);
  color: rgb(187,136,136);
  min-width: 7rem;
  min-height: 2.5rem;
  height: 100%;
}

.user-button.small {
  min-width: 3rem;
  font-size: .75rem;
}

.user-button:hover {
  color: rgb(54,1,1);
  background-color: rgb(187,136,136);
}

.header-user {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 10px;

  color: white;
  font-size: .75rem;
  border-bottom: 3px rgb(54,1,1) solid;
  /* border-left: 3px rgb(54,1,1) solid; */
}

.header-user:hover {
  background-color: rgb(54,1,1);;
}

/* MOBILE */

@media screen and (max-width: 650px) {

  .navbar {
    flex-flow: column nowrap;
    justify-content: center;
  }
  
  .other-nav {
    display: none;
    width: 100%;
  }
  
  .other-nav.open {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    overflow: visible;
  }

  .navbar a {
    /* width: 15rem; */
    width: 100%;
    min-height: 3rem;
    padding: .5rem;
    text-align: left;
    align-self: flex-end;
  }

  .user-button {
    /* width: 15rem; */
    width: 100%;
    height: 3.5rem;

    align-self: flex-end;
  }

  .icon {
    display: block;
  }

  .header-user {
    /* min-width: 15rem; */
    width: 100%;
    border-bottom: none;
    border-top: 3px rgb(54,1,1) solid;
    align-self: flex-end;
  }

  .header-user div {
    height: 2.5rem;
    /* hack to center vertically */
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
  }
  
}